<template>
  <div
    class="main-article"
    element-loading-text="正在生成页面"
    v-loading.fullscreen.lock="fullscreenLoading"
  >
    <div class="printbtn">
      <p style="color: red">注意事项：</p>
      <p>如果需要导出PDF文件，则在页面打印设置中,选择保存到PDF文件</p>
    </div>
    <div v-print="printObj" type="primary" plain   id="printBtn">打印报告</div>
    <div id="content_b">
      <div
        class="content PageNext page"
        id="content_a"
        v-for="(item, key) in tableInfoList"
        :key="key"
        :style="{ fontSize }"
        
      >
        <!-- 医院名称 -->
        <div class="units_name" style="margin-bottom: 30px">
          <p style="font-size: 33px; font-weight: 700; text-align: center">
            {{ config.unit_name }}
          </p>
        </div>
        <!-- 普通报告 -->
        <div v-if="!item.results">
          <!-- 标题 -->
          <div class="report-title">
            <p>{{ item.measure_title }}</p>
          </div>
          <!-- 用户信息 -->
          <div class="userInfo" v-show="form.scope_basic == 1">
            <table>
              <tr>
                <td>
                  姓名：
                  <span>{{ item.customer.name }}</span>
                </td>
                <td>
                  性别：
                  <span>{{ item.customer.sex }}</span>
                </td>
                <td>
                  年龄：
                  <span>{{ item.customer.birthday | filtrationBirthday }}</span>
                </td>
              </tr>
              <tr>
                <td>
                  住院号/登记号：
                  <span>{{ item.customer.account }}</span>
                </td>
                <td>
                  科室：
                  <span>{{ item.customer.department }}</span>
                </td>
                <td>
                  报告日期：
                  <span>{{ item.customer.evaluating_time }}</span>
                </td>
              </tr>
              <template v-if="item.customer.archives">
                <tr>
                  <td
                    v-for="(item2, key) in item.customer.archives.slice(0, 3)"
                    :key="key"
                  >
                    {{ item2.name }} :
                    <span>{{ item2.value }}</span>
                  </td>
                </tr>
                <tr>
                  <td
                    v-for="(item2, key) in item.customer.archives.slice(3, 6)"
                    :key="key"
                  >
                    {{ item2.name }} :
                    <span>{{ item2.value }}</span>
                  </td>
                </tr>
                <tr>
                  <td
                    v-for="(item2, key) in item.customer.archives.slice(6, 9)"
                    :key="key"
                  >
                    {{ item2.name }} :
                    <span>{{ item2.value }}</span>
                  </td>
                </tr>
                <tr>
                  <td
                    v-for="(item2, key) in item.customer.archives.slice(9, 12)"
                    :key="key"
                  >
                    {{ item2.name }} :
                    <span>{{ item2.value }}</span>
                  </td>
                </tr>
              </template>
            </table>
          </div>
          <!-- 测试结果title -->
          <p class="test-msg" v-show="form.scope_graph == 1">测试结果:</p>
          <!-- 测试-图 -->
          <div class="chart" v-show="form.scope_graph == 1">
            <!-- 90,圆形图 -->
            <div
              :id="'chart-report' + key"
              :style="{ width: '100%', height: '100%' }"
            ></div>
          </div>
          <!-- 测试-表 -->
          <div class="table" v-show="form.scope_factor == 1">
            <div class="table-main">
              <el-row v-if="item.factor_results.length <= 3">
                <!-- 因子数小于三 -->
                <el-col>
                  <table class="one">
                    <tr>
                      <th>检测项目</th>
                      <th>程度</th>
                      <th>得分</th>
                    </tr>
                    <!-- -------------检测项目---------------- -->
                    <tr v-for="(item2, key2) in item.factor_results" :key="key2">
                      <td>{{ item2.name }}</td>
                      <td>{{ item2.mark }}</td>
                      <td>{{ item2.score }}</td>
                    </tr>
                  </table>
                </el-col>
              </el-row>
              <el-row v-if="item.factor_results.length > 3">
                <table class="results-two" cellspacing="0">
                  <thead>
                    <tr>
                      <th>检测项目</th>
                      <th>程度</th>
                      <th>得分</th>
                      <th>检测项目</th>
                      <th>程度</th>
                      <th>得分</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item2, key2) in item.factor_results" :key="key2">
                      <template v-if="item.factor_results[key2 * 2]">
                        <td>{{ item.factor_results[key2 * 2].name }}</td>
                        <td>{{ item.factor_results[key2 * 2].mark }}</td>
                        <td>{{ item.factor_results[key2 * 2].score }}</td>
                      </template>
                      <template v-if="item.factor_results[key2 * 2 + 1]">
                        <td>{{ item.factor_results[key2 * 2 + 1].name }}</td>
                        <td>{{ item.factor_results[key2 * 2 + 1].mark }}</td>
                        <td>{{ item.factor_results[key2 * 2 + 1].score }}</td>
                      </template>
                    </tr>
                  </tbody>
                </table>
              </el-row>
            </div>
          </div>
          <!-- 测试结果title -->
          <p
            class="test-msg"
            v-show="
              form.scope_explain != 0 ||
              form.scope_explain != 0 ||
              form.scope_suggest != 0
            "
          >
            报告分析:
          </p>
          <!-- 健康状态 -->
          <!-- <div class="condition">
            <table>
              <tr class="condition-title">【心理健康状况】 分数级别：心理健康状况良好 得分：153</tr>
              <tr>
                <td class="condition-title">【说 明】</td>
              </tr>
              <tr>
                <td>被测者的心理健康状况良好。被测试者很少因心理原因出现身体不适感。能积极面对社会和生活中的各种问题，心理状态好，信任他人，对人友善。一般没有过于焦虑、悲伤等情况。总之，被测试者拥有比较健康的心态和情绪，能够较好地处理生活中的挫折和压力。能很好的适应工作、社会生活，并从中获得满足和快乐，个人价值感和幸福感较高。</td>
              </tr>
              <tr>
                <td class="condition-title">【指导建议】</td>
              </tr>
              <tr>
                <td>被测者的心理健康状况良好。希望被试继续保持这种积极的心态和良好的行为方式。健康对我们每个人的生活和工作都起着重要的作用，好的身体来源于健康的心态，能解除心理疲劳。好的心态有助于我们克服困难，即使受到挫折与坎坷，依然能够保持乐观的情绪，保持旺盛的斗志。你也可以从以下方面进一步提高你的生活质量，如多与人为善，建立完善的人际网络作为自己心理支持的后盾，掌握一些保持身心健康的常识和技巧，保持乐观、自信的心态面对生活等。</td>
              </tr>
            </table>
          </div>-->
          <!-- 每个项目分析 -->
          <div
            class="analyze"
            v-show="
              form.scope_explain != 0 ||
              form.scope_explain != 0 ||
              form.scope_suggest != 0
            "
          >
            <!-- v-show  隐藏没内容的 因子 -->
            <table
              v-for="(item2, key2) in item.factor_results"
              :key="key2"
              v-show="item2.comment != '' || item2.advice != ''"
            >
              <!--  v-show="item2.comment" -->
              <tr class="analyze-title" v-show="form.scope_score == 1">
                【{{
                  item2.name
                }}】 分数级别：{{
                  item2.mark
                }}
                <span>得分：{{ item2.score }}</span>
                <!-- v-if="item2.comment" -->
              </tr>
              <tr v-if="item2.comment" v-show="form.scope_explain == 1">
                <td class="analyze-title">【说 明】</td>
              </tr>
              <tr v-if="item2.comment" v-show="form.scope_explain == 1">
                <td v-html="item2.comment"></td>
              </tr>
              <tr v-if="item2.advice" v-show="form.scope_suggest == 1">
                <td class="analyze-title">【指导建议】</td>
              </tr>
              <tr v-if="item2.advice" v-show="form.scope_suggest == 1">
                <td v-html="item2.advice"></td>
              </tr>
            </table>
          </div>
          <!-- 配合程度 -->
          <div class="degree" style="margin: 10px 0" v-if="isDegree">
            <p style="font-weight: 700; margin-bottom: 10px">【配合程度】</p>
            <p style="text-indent: 2em">{{ degree }}</p>
          </div>
          <!-- 医生签名 -->
          <div class="signature">
            <span class="title">
              <span style="margin-right: 30px" v-if="form.scope_assessor == 1"
                >测评护士:_______________</span
              >
              报告医生 ：
              <span class="signature_img">
                <img :src="item.admin.sign_img" alt width="150" />
              </span>
            </span>
            <span class="title data">报告日期 ：</span>
            <span>{{ item.customer.evaluating_time }}</span>
          </div>
          <!-- 用户答题 -->
          <div
            style="page-break-after: always"
            v-if="form.scope_question == 1"
          ></div>
          <div class="user-answers atable" v-if="form.scope_question == 1">
            <p class="test-msg">用户答案:</p>

            <table
              v-if="item.question && form.scope_question == 1"
              cellspacing="0"
              style="
                width: 100%;
                border-top: 1px dashed black;
                border-left: 1px dashed black;
              "
            >
              <thead>
                <tr>
                  <th
                    style="
                      padding: 5px 0;
                      border-bottom: 1px dashed black;
                      border-right: 1px dashed black;
                      width: 160px;
                    "
                  >
                    问题
                  </th>
                  <th
                    style="
                      padding: 5px 0;
                      border-bottom: 1px dashed black;
                      border-right: 1px dashed black;
                      width: 180px;
                    "
                  >
                    答案
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item2, key) in item.question" :key="key">
                  <td
                    v-html="item2.question"
                    style="
                      box-sizing: border-box;
                      padding: 2px 5px 2px 5px;
                      vertical-align: middle;
                      border-bottom: 1px dashed black;
                      border-right: 1px dashed black;
                      text-align: center;
                      width: 160px;
                    "
                  ></td>
                  <td
                    style="
                      box-sizing: border-box;
                      padding: 2px 5px 2px 5px;
                      vertical-align: middle;
                      border-bottom: 1px dashed black;
                      border-right: 1px dashed black;
                      text-align: center;
                      width: 160px;
                    "
                  >{{ '' + item2.answer || item.inputs[key + 1]  }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- 明尼苏达报告 -->
        <div v-if="item.results" class="content minnesota" :style="{ fontSize }">
          <!-- 标题  -->
          <div class="report-title">
            <p>{{ item.measure_title }}</p>
          </div>
          <!-- 用户信息 -->
          <div class="userInfo" v-show="form.scope_basic == 1">
            <table>
              <tr>
                <td>
                  姓名：
                  <span>{{ item.customer.name }}</span>
                </td>
                <td>
                  性别：
                  <span>{{ item.customer.sex }}</span>
                </td>
                <td>
                  年龄：
                  <span>{{ item.customer.birthday | filtrationBirthday }}</span>
                </td>
              </tr>
              <tr>
                <td>
                  住院号/登记号：
                  <span>{{ item.customer.account }}</span>
                </td>
                <td>
                  科室：
                  <span>{{ item.customer.department }}</span>
                </td>
                <td>
                  报告日期：
                  <span>{{ item.customer.evaluating_time }}</span>
                </td>
              </tr>
              <template v-if="item.customer.archives">
                <tr>
                  <td
                    v-for="(item2, key) in item.customer.archives.slice(0, 3)"
                    :key="key"
                  >
                    {{ item2.name }} :
                    <span>{{ item2.value }}</span>
                  </td>
                </tr>
                <tr>
                  <td
                    v-for="(item2, key) in item.customer.archives.slice(3, 6)"
                    :key="key"
                  >
                    {{ item2.name }} :
                    <span>{{ item2.value }}</span>
                  </td>
                </tr>
                <tr>
                  <td
                    v-for="(item2, key) in item.customer.archives.slice(6, 9)"
                    :key="key"
                  >
                    {{ item2.name }} :
                    <span>{{ item2.value }}</span>
                  </td>
                </tr>
                <tr>
                  <td
                    v-for="(item2, key) in item.customer.archives.slice(9, 12)"
                    :key="key"
                  >
                    {{ item2.name }} :
                    <span>{{ item2.value }}</span>
                  </td>
                </tr>
              </template>
              <tr>
                <td>
                  项目数:
                  <span>{{
                    item.results.mmpi.statistics.dashi +
                    item.results.mmpi.statistics.dafou
                  }}</span>
                </td>
                <td>
                  答是数量:
                  <span>{{ item.results.mmpi.statistics.dashi }}</span>
                </td>
                <td>
                  答否数量:
                  <span>{{ item.results.mmpi.statistics.dafou }}</span>
                </td>
              </tr>
            </table>
          </div>
          <br />
          <!-- MMPI 基 本 量 表 分 -->
          <div class="mmpi">
            <div class="title">MMPI 基 本 量 表 分</div>
            <el-divider></el-divider>
            <div class="mmpi_table">
              <!-- 2 -->
              <table>
                <tr>
                  <td>
                    <span></span>
                  </td>
                  <td
                    v-for="(res, key, index) in item.results.mmpi.help"
                    :key="index"
                  >
                    <span>{{ key }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>分数：</span>
                  </td>
                  <td
                    v-for="(res, key, index) in item.results.mmpi.help"
                    :key="index"
                  >
                    <span>{{ res }}</span>
                  </td>
                </tr>
              </table>
              <el-divider></el-divider>
              <!-- 4 -->
              <table>
                <tr>
                  <td>
                    <span>原始分：</span>
                  </td>
                  <td
                    v-for="(res, key, index) in item.results.mmpi.oldCore"
                    :key="index"
                  >
                    <span>{{ res }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>K 校 正 分：</span>
                  </td>
                  <td
                    v-for="(res, key, index) in item.results.mmpi.kCore"
                    :key="index"
                  >
                    <span>{{ res }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>中国常模不做K校正的T分：</span>
                  </td>
                  <td
                    v-for="(res, key, index) in item.results.mmpi.kNoCmCore"
                    :key="index"
                  >
                    <span>{{ res }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>中国常模做K校正的T分：</span>
                  </td>
                  <td
                    v-for="(res, key, index) in item.results.mmpi.kCmCore"
                    :key="index"
                  >
                    <span>{{ res }}</span>
                  </td>
                </tr>
              </table>
              <el-divider></el-divider>
              <div class="mmpi_summarize">
                <span>两点峰型:{{ item.results.mmpi.statistics.twofx }}</span>
                <el-divider direction="vertical"></el-divider>
                <span>矛盾题:{{ item.results.mmpi.statistics.maodun }}</span>
                <el-divider direction="vertical"></el-divider>
                <span
                  >答"是"的比例:{{ item.results.mmpi.statistics.pro_yes }} %</span
                >
                <el-divider direction="vertical"></el-divider>
                <span
                  >答"否"的比例:{{ item.results.mmpi.statistics.pro_no }} %</span
                >
              </div>
              <el-divider></el-divider>
            </div>
          </div>
          <br />
          <!-- T 分 顺 序 表 -->
          <div class="t">
            <div class="title">T 分 顺 序 表</div>
            <el-divider></el-divider>
            <div class="t_table">
              <!-- 分 -->
              <table>
                <tr>
                  <td
                    v-for="(res, key, index) in item.results.tcore.core"
                    :key="index"
                  >
                    <span>{{ key }}</span>
                  </td>
                </tr>
                <tr>
                  <td
                    v-for="(res, key, index) in item.results.tcore.core"
                    :key="index"
                  >
                    <span>{{ res }}</span>
                  </td>
                </tr>
              </table>
              <!-- 表格 -->
              <table>
                <tr>
                  <td>因子量表</td>
                  <td>T 分</td>
                  <td>低分者特征</td>
                  <td>
                    <span>10</span>
                    <span>20</span>
                    <span>30</span>
                    <span>40</span>
                    <span>50</span>
                    <span>60</span>
                    <span>70</span>
                    <span>80</span>
                    <span>90</span>
                    <span>100</span>
                    <span>110</span>
                  </td>
                  <td>高分者特征</td>
                </tr>
                <tr v-for="(res, key2) in item.results.tcore.tdes" :key="key2">
                  <!-- 因 返回的数据 0-3为 一条td，故 %3 处理 不显示  -->
                  <template v-if="key2 % 3 == 0">
                    <!-- 因子量表 -->
                    <td>
                      {{ res.tagdes }}
                      <br />
                      <span
                        v-html="item.results.tcore.tdes[key2 + 1].tagdes"
                      ></span>
                    </td>
                    <!-- 分数 -->
                    <td>{{ res.score }}</td>
                    <!-- 低分者特征 -->
                    <td>
                      {{ res.ddesc }}
                      <br />
                      {{ item.results.tcore.tdes[key2 + 1].ddesc }}
                    </td>
                    <!-- 游标 -->
                    <td>
                      <span
                        class="cursor"
                        :style="`left:${
                          (res.score / 10) * 25.91 - 25.91 / 2 - 4
                        }px`"
                      >
                        <i class="el-icon-caret-top"></i>
                      </span>
                    </td>
                    <!-- 高 分者特征 -->
                    <td>
                      {{ res.gdesc }}
                      <br />
                      {{ item.results.tcore.tdes[key2 + 1].gdesc }}
                    </td>
                  </template>
                </tr>
              </table>
              <br />
              <!-- 图表 -->
              <div class="chart seal" v-show="form.scope_graph == 1">
                <!-- 90,圆形图 -->
                <div
                  :id="'chart-report' + key"
                  :style="{ width: '100%', height: '100%' }"
                ></div>
              </div>
              <!-- 预测内容 -->
              <div class="forecast" v-if="item.results.war">
                <table>
                  <caption>
                    危险行为预测
                  </caption>
                  <tr>
                    <td style="text-align: center">预测内容</td>
                    <td>1</td>
                    <td>2</td>
                    <td>3</td>
                    <td>4</td>
                    <td>5</td>
                    <td>6</td>
                    <td>7</td>
                    <td>8</td>
                    <td>9</td>
                    <td>10</td>
                  </tr>
                  <tr v-for="(res, k, index) in item.results.war" :key="index">
                    <td style="text-align: left">{{ k }}:</td>
                    <td
                      v-for="index2 in 10"
                      :key="index2"
                      :colspan="res"
                      style="background: rgb(63, 62, 62)"
                      v-show="index2 == 1"
                    ></td>
                  </tr>
                  <!-- 程度 -->
                  <tr style="transform: translateX(4px); font-weight: 700">
                    <td></td>
                    <td>小</td>
                    <td></td>
                    <td></td>
                    <td>中</td>
                    <td></td>
                    <td>大</td>
                    <td></td>
                    <td>极大</td>
                    <td></td>
                    <td></td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <br />
          <!-- 临 床 亚 量 表 -->
          <div class="conclusion">
            <table>
              <caption>
                临 床 亚 量 表
              </caption>
              <tr>
                <th>
                  <span>量表</span>
                </th>
                <th>
                  <span>T分</span>
                </th>
                <th>
                  <span>量表结果的解释</span>
                </th>
              </tr>
              <tr v-for="(r, k, index) in item.results.lc" :key="index">
                <td>{{ r.name }}</td>
                <td>
                  <span>{{ r.score }}</span>
                </td>
                <td>{{ r.comment }}</td>
              </tr>
            </table>
            <div class="remind">
              <p>
                注：量表结果的解释为空表明该项为正常或大多数人的分数在此范围。
              </p>
            </div>
          </div>
          <!-- 附加量表 -->
          <div class="conclusion" v-if="item.results.fjlb">
            <table>
              <caption>
                附 加 量 表
              </caption>
              <tr>
                <th>
                  <span>量表</span>
                </th>
                <th>
                  <span>T分</span>
                </th>
                <th>
                  <span>量表结果的解释</span>
                </th>
              </tr>
              <tr v-for="(r, k2) in item.results.fjlb" :key="k2">
                <td>{{ r.name }}</td>
                <td>
                  <span>{{ r.score }}</span>
                </td>
                <td>{{ r.comment }}</td>
              </tr>
            </table>
            <div class="remind">
              <p>
                注：量表结果的解释为空表明该项为正常或大多数人的分数在此范围。
              </p>
            </div>
          </div>
          <!-- 适应不良量表 -->
          <div class="conclusion" v-if="item.results.sybllb">
            <table>
              <caption>
                适 应 不 良 量 表
              </caption>
              <tr>
                <th>
                  <span>量表</span>
                </th>
                <th>
                  <span>T分</span>
                </th>
                <th>
                  <span>量表结果的解释</span>
                </th>
              </tr>
              <tr v-for="(r, k2) in item.results.sybllb" :key="k2">
                <td>{{ r.name }}</td>
                <td>
                  <span>{{ r.score }}</span>
                </td>
                <td>{{ r.comment }}</td>
              </tr>
            </table>
            <div class="remind">
              <p>
                注：量表结果的解释为空表明该项为正常或大多数人的分数在此范围。
              </p>
            </div>
          </div>
          <!-- 预测性量表 -->
          <div class="conclusion" v-if="item.results.ycxlb">
            <table>
              <caption>
                预 测 性 量 表
              </caption>
              <tr>
                <th>
                  <span>量表</span>
                </th>
                <th>
                  <span>T分</span>
                </th>
                <th>
                  <span>量表结果的解释</span>
                </th>
              </tr>
              <tr v-for="(r, k2) in item.results.ycxlb" :key="k2">
                <td>{{ r.name }}</td>
                <td>
                  <span>{{ r.score }}</span>
                </td>
                <td>{{ r.comment }}</td>
              </tr>
            </table>
            <div class="remind">
              <p>
                注：量表结果的解释为空表明该项为正常或大多数人的分数在此范围。
              </p>
            </div>
          </div>
          <!-- 性功能和性体验障碍量表 -->
          <div class="conclusion" v-if="item.results.xgn">
            <table>
              <caption>
                性 功 能 和 性 体 验 障 碍 量 表
              </caption>
              <tr>
                <th>
                  <span>量表</span>
                </th>
                <th>
                  <span>T分</span>
                </th>
                <th>
                  <span>量表结果的解释</span>
                </th>
              </tr>
              <tr v-for="(r, k2) in item.results.xgn" :key="k2">
                <td>{{ r.name }}</td>
                <td>
                  <span>{{ r.score }}</span>
                </td>
                <td>{{ r.comment }}</td>
              </tr>
            </table>
            <div class="remind">
              <p>
                注：量表结果的解释为空表明该项为正常或大多数人的分数在此范围。
              </p>
            </div>
          </div>
          <!-- 内容量表 -->
          <div class="conclusion" v-if="item.results.nrlb">
            <table>
              <caption>
                内 容 量 表
              </caption>
              <tr>
                <th>
                  <span>量表</span>
                </th>
                <th>
                  <span>T分</span>
                </th>
                <th>
                  <span>量表结果的解释</span>
                </th>
              </tr>
              <tr v-for="(r, k2) in item.results.nrlb" :key="k2">
                <td>{{ r.name }}</td>
                <td>
                  <span>{{ r.score }}</span>
                </td>
                <td>{{ r.comment }}</td>
              </tr>
            </table>
            <div class="remind">
              <p>
                注：量表结果的解释为空表明该项为正常或大多数人的分数在此范围。
              </p>
            </div>
          </div>
          <br />
          <!--辅助诊断意见 -->
          <div class="auxiliary" v-if="item.results.xlzz">
            <div class="title">* * *辅助诊断意见(仅供临床参考)* * *</div>
            <el-divider></el-divider>
            <div class="auxiliary_title">* * *心理症状提示* * *</div>
            <el-divider></el-divider>
            <div class="auxiliary_main">
              <div
                class="auxiliary_item"
                v-for="(r2, k2) in item.results.xlzz"
                :key="k2"
              >
                <div class="item_title">
                  {{ k2 + 1 }}、{{ r2.title }}({{ r2.fz }}/{{ r2.fm }})：
                </div>
                <div class="item_content" v-html="r2.desc"></div>
              </div>
            </div>
          </div>
          <br />
          <!-- 个性心理描述 -->
          <div class="describe">
            <div class="title">* * * 个性心理描述 * * *</div>
            <el-divider></el-divider>
            <!-- 测谎 -->
            <div class="describe_main">
              <div class="describe_content_title">
                <p>测谎</p>
                <el-divider></el-divider>
              </div>
              <div class="describe_content">
                <p v-html="item.results.gxdesc.l"></p>
              </div>
            </div>
            <!-- 总效度分析 -->
            <div class="describe_main">
              <div class="describe_content_title">
                <p>总效度分析</p>
                <el-divider></el-divider>
              </div>
              <div class="describe_content">
                <p v-html="item.results.gxdesc.fx1"></p>
              </div>
            </div>
            <!-- 个性逐项分析 -->
            <div class="describe_main">
              <div class="describe_content_title">
                <p>个性逐项分析</p>
                <el-divider></el-divider>
              </div>
              <div class="describe_content">
                <p v-html="item.results.gxdesc.fx2"></p>
              </div>
            </div>
            <!-- 个性综合分析(本人常意识不到,应激,虚弱和疾病时明显) -->
            <div class="describe_main">
              <div class="describe_content_title">
                <p>个性综合分析(本人常意识不到,应激,虚弱和疾病时明显)</p>
                <el-divider></el-divider>
              </div>
              <div class="describe_content">
                <p v-html="item.results.gxdesc.fx3"></p>
              </div>
            </div>
          </div>
          <div class="remind">
            <p style="text-align: center">
              ******（本报告仅供临床参考，不作诊断证明之用）******
            </p>
          </div>
          <!-- 总 体 描 述 -->
          <div class="zDescribe" v-if="item.results.ztyx">
            <div class="zDescribe_title">总 体 描 述</div>
            <el-divider></el-divider>
            <div class="zDescribe_content">
              <p>{{ item.results.ztyx.desc }}</p>
            </div>
            <div class="zDescribe_msg">
              <p>
                检 查 结 果:
                <span>{{ item.results.ztyx.con }}</span>
              </p>
            </div>
          </div>
          <!-- 配合程度 -->
          <div class="degree" style="margin: 10px 0" v-if="isDegree">
            <p style="font-weight: 700; margin-bottom: 10px">【配合程度】</p>
            <p style="text-indent: 2em">{{ degree }}</p>
          </div>
          <!-- 医生建议 -->
          <br />
          <div class="suggest seal">
            <p class="suggest_msg">医生建议：</p>
            <div class="suggest_content"></div>
            <!-- 医生签名 -->
            <div class="signature">
              <span class="title">
                <span style="margin-right: 30px" v-if="form.scope_assessor == 1"
                  >测评护士:_______________</span
                >
                报告医生 ：
                <span class="signature_img">
                  <!-- <img :src="item.admin.sign_img" alt width="150" /> -->
                </span>
              </span>
              <span class="title data">报告日期 ：</span>
              <span>{{ item.customer.evaluating_time }}</span>
            </div>
          </div>
          <div class="remind">
            <p style="text-align: center">
              ******此报告仅供参考，请以医师诊断为准。******
            </p>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { loadReport ,setPrint } from "@/api/report.js";
import moment from "moment";
export default {
  data() {
    return {
      isDegree: false,
      degree: "",
      article: "",
      fullscreenLoading: true,
      // 打印设置
      printObj: {
        id: "content_b",
        popTitle: "新谦雅心理CT系统v6.0",
        closeCallback(_this) {
          // console.log("已经打印了111");
          _this.endChange()

        }
      },
      userReporData: {
        customer: {
          id: 1,
          account: "iEfoam",
          name: "iEfoam",
          sex: "男",
          birthday: "1997-05-29",
          department_id: 1,
          unit_name: "重庆医科大学附属第二医院",
          department_name: "技术部门",
          age: 22,
        },
        measure_title: "心理健康临床症状自评量表(SCL-90)",
        theme: 3,
        base_results: {
          name: "心理健康状况",
          score: 30.3,
          factor_id: 222,
          mark: "心理健康状况良好",
          comment:
            "测试结果表明被测者的心理健康状况良好。被测者很少因心理原因出现身体不适感。能积极面对社会和生活中的各种问题，心理状态好，信任他人，对人友善。一般没有过于焦虑、悲伤等情况。总之，被测者拥有比较健康的心态和情绪，能够较好地处理生活中的挫折和压力。能很好的适应工作、社会生活，并从中获得满足和快乐，个人价值感和幸福感较高。",
          advice:
            "被测者的心理健康状况良好。希望被试继续保持这种积极的心态和良好的行为方式。健康对我们每个人的生活和工作都起着重要的作用，好的身体来源于健康的心态，能解除心理疲劳。好的心态有助于我们克服困难，即使受到挫折与坎坷，依然能够保持乐观的情绪，保持旺盛的斗志。你也可以从以下方面进一步提高你的生活质量，如多与人为善，建立完善的人际网络作为自己心理支持的后盾，掌握一些保持身心健康的常识和技巧，保持乐观、自信的心态面对生活等。",
        },
        warning: 0,
        factor_results: [
          {
            name: "躯体化",
            score: 2.5,
            factor_id: 212,
            mark: "主观感觉身体轻度不适",
            comment:
              "测试结果表明被测者主观感觉身体轻度不适。被测者会经常感到身体某些部位不适，比如心血管、胃肠道、呼吸和其他系统的不适或头晕、头痛、心慌、胸闷、胃部不适等。症状严重的被测者可能因此会反复去医院检查，但都没有任何结果，而被测者认为这些症状是躯体性的（器质性病变所引起）要求进一步检查。",
            advice:
              "被测者主观感觉身体不适。被测者应该首先到医院检查看是否有器质性病变，如果没有器质性病变，则应注意这些症状出现前是否承受了巨大心理压力，或者长期以来都有心理困扰，如果存在这些心理压力很可能是心理因素的躯体化表现。这时，减轻躯体不适的关键是消除这些不健康的心理因素。被测者即不应该忽视身体的不适，也不必过分关注和夸大这些不适，而应“对症下药”。保持心情愉悦、正确认识和应对压力、适度的体育锻炼，对维持身心健康必不可少。",
          },
          {
            name: "强迫症状",
            score: 3.5,
            factor_id: 213,
            mark: "存在中度强迫症状",
            comment:
              "测试结果表明被测者在生活中经常感到自身存在中度强迫性的想法或行为，即明知不必要却无法克制地要去想或要去做某事。比如无法停止反复想没有意义的事情；经常没有必要地反复做某些事情，例如检查门窗，开关，煤气，钱物，文件，表格，信件等；经常反复洗手而且洗手的时间很长，超过正常所需要；经常对病菌和各种疾病敏感，并毫无必要的担心等。这些强迫想法和行为让被测者感到非常痛苦和焦虑，他们总是力图摆脱但往往越是企图抵制，反到感到紧张和痛苦。",
            advice:
              "被测者在生活中经常感到自身存在中度强迫性的想法或行为。运用“听其自然”方法，不要苛求自己，该怎么办就怎么办，做了以后就不要再去想它，也不要去评价它，议论它。如果出现异常观念和行为要从认知上进行调节，要么完全接受它，顺其自然，要么通过夸张的想法，使其达到荒诞透顶的程度，以致自己也觉得很可笑，由此消除强迫想法或行为。当自己处于莫名其妙的紧张和焦虑状态时可以进行自我暗示，说服自己不要紧张。当出现强迫现象采用其它活动来转移或直接对抗强迫思维，如高声唱歌，背诵诗词等。被测者还可以寻求专业心理工作者的帮助。",
          },
          {
            name: "人际关系敏感",
            score: 2.33,
            factor_id: 214,
            mark: "存在轻度人际关系敏感症状",
            comment:
              "测试结果表明被测者存在轻度人际关系敏感的问题。被测者在与人交往时缺乏自信，敏感多虑，和人相处谨小慎微。容易和他人保持一定距离，虽然自己也希望拥有“知心朋友”，但又难与人建立亲密关系。与人相处很不自然，小心翼翼，害怕带给人麻烦，害怕别人嫌弃自己。有时会表现得特别有礼貌，实则给人距离和拘谨。很在意他人对自己的评价，很关注自己的言行是否得当。有的表现为特别害怕与异性交往。",
            advice:
              "被测者存在人际关系敏感的问题。被测者应加强在人际交往过程中的信心，不要过分注意自己的言行是否妥当，也不必太过在意他人对你的评价，应自然、自信的交往。多找些自己和他人的长处，同时正视自己的缺点，遇到让自己感到难堪的时刻，学会自我解嘲。建立自己的积极形象，想象自己希望成为的样子，越细致越好，这种积极的自我暗示有助于增加对自己的接纳和信心。同时，应适当了解对方的背景和需要，即提高人际“敏感度”。",
          },
          {
            name: "抑郁",
            score: 1.92,
            factor_id: 215,
            mark: "不存在抑郁症状",
            comment:
              "测试结果表明被测者不存在抑郁症状。被测者精力旺盛，对生活充满信心和希望、兴趣广泛，自尊感高。即使偶尔遇到一些困难和挫折，也能很好应对和调节，并能从他人那里获得支持和帮助。",
            advice:
              "被测者不存在抑郁症状。希望被测者在今后的日子里继续保持开朗乐观的心态，因为生活中难免不会遇到挫折和暂时的低谷，而真正的力量来自心的力量，所以健康的心理是战胜困难的法宝。暂时的情绪低落和消沉没有什么可怕，要认识到这些情绪产生的原因，理性分析问题，调节不良情绪，选择恰当应对方式。",
          },
          {
            name: "焦虑分量表",
            score: 3.8,
            factor_id: 216,
            mark: "存在中度焦虑症状",
            comment:
              "测试结果表明被测者存在中度焦虑症状。被测者时常会有焦虑、烦躁、恐惧、紧张和不安情绪，经常为一些尚未发生的事情担心而坐立不安，小动作增多,注意力无法集中,自己也不知道为什么如此惶恐不安。对未来生活缺乏信心和乐趣.有时情绪激动,失去平衡,经常无故地发怒,与家人争吵,对什么事情都看不惯,不满意。除这些不良情绪外，往往会伴有一些躯体症状，如心悸,心慌,胸闷,气短等。",
            advice:
              "被测者存在焦虑症状。适度的焦虑有利于人们调动自身的资源（智力和体力）来应对问题，这是人类正常的应激机制，所以不必担心。但过度或长时间的焦虑对个体的身心健康有很大的危害，一方面，焦虑是许多心理症状和一些生理症状的直接原因。另一方面，过度的焦虑对人的行为、智力、人格等造成不良的影响。由于被测者目前已经存在焦虑症状，建议被测者用以下方法应对焦虑：(1)可找朋友谈，参加一些文体活动，使自己的焦虑郁闷情绪得以宣泄而达到情绪的稳定。（2）正确评价自己，既看到自己的优势，也要看到自己的不足；期望值不要定得太高，要正视现实，理想与现实之间的距离不要太大。不妨调整一下自己的目标，就能从困境中得到解脱。（3）以足够的睡眠消除疲劳，换取充沛的精力和清醒的头脑。",
          },
          {
            name: "敌对",
            score: 3.67,
            factor_id: 217,
            mark: "存在中度敌对症状",
            comment:
              "测试结果表明被测者存在中度敌对症状。对一些给自己打击的人或和自己意见相左的人产生敌对情绪，认为有些人总是居心叵测或者有意针对自己，防范心理相对比较重。可能因一些很小的事情就与人发生口角，甚至摔物或争斗。情绪易激动，且不容易控制。存在一定人际关系问题。",
            advice:
              "被测者存在敌对症状。被测者对他人的敌对情绪和行为一方面反应了自我控制力弱，另一方面表明存在认识或人格上的缺陷。比如可能不够豁达，即使很小的事情也耿耿于怀，无法站在他人的角度看到问题等。建议被测者在与人交往时，如果出现敌对的思想，要用理智来克制自己的情感，使敌意、怒气渐渐消除、化解。遇事不能鲁莽，应设身处地地替别人想一想，这样才能理解他人的观点和行为举止。在生活中，人与人之际爱你难免有误解，而一个得体的幽默，往往能使双方摆脱困窘的境地。与人叫我那个应不抱成见，寻找机会取得他人的信任，奉行以诚相待的原则。",
          },
          {
            name: "恐怖",
            score: 2.71,
            factor_id: 218,
            mark: "存在轻度恐怖症状",
            comment:
              "测试结果表明被测者存在轻度恐怖症状。被测者会有异常恐怖和害怕现象，害怕开放的空间或害怕离家（或独自在家），也包括害怕置身于人群拥挤场合以及难以逃回安全处所（多为家）的其他地方如商店、剧院、车厢等。尽管当时并无真正危险，但患者仍然极力回避所害怕的物体或处境。害怕并回避与某人或某类型人交往的情景。",
            advice:
              "被测者存在恐怖症状。如果被测者自感恐惧心理不很严重，可先从认知上接受恐惧发生时的身心变化，不要去抗拒、抑制或掩饰它；同时，进行放松训练，如感到恐惧时，有意识地做深呼吸，放松全身。对于比较严重的症状，目前最为常用的是系统脱敏法，这种方法让患者循序渐进地暴露于引起焦虑、恐惧的刺激，使患者对恐怖刺激的敏感性逐渐降低。严重的恐怖症患者还应配合药物治疗，如抗焦虑药、抗抑郁药。自感症状严重者建议寻求心理咨询师的帮助。",
          },
          {
            name: "偏执",
            score: 3.17,
            factor_id: 219,
            mark: "存在中度偏执症状",
            comment:
              "测试结果表明被测者存在中度偏执症状。被测者对周围事情的解释经常不符合实际情况，脱离实际地好争辩与敌对，固执地追求个人不够合理的“权利”或利益，且很难用说理或事实来改变被测者的想法。会有一些偏执想法和行为，如无根据的怀疑被人利用或伤害，过分自信，把错误和失败都归咎于他人，妄想某种关系的存在等等。",
            advice:
              "被测者存在偏执症状。被测者应克服多疑敏感、固执、不安全感和自我中心的人格缺陷。可以从以下几个方面进行校正：（1）与他人建立信任关系，在相互信任的基础上交流情感，以诚相待，告诉对方自己在人格上缺陷和想改变的愿望。（2）积极主动地进行交友活动，在交友中学会信任他人，消除不安全感。（3）自我反省，认识自己的偏执观念，并对这些观念加以改造，以除去其中极端偏激的成份。",
          },
          {
            name: "精神病性",
            score: 3.7,
            factor_id: 220,
            mark: "存在中度精神病性症状",
            comment:
              "测试结果表明被测者存在中度精神病性症状。有时会出现幻觉，比如凭空听到有人在说话，看到或感觉到现实情况下不存在的东西。有如下精神病性症状：感到别人有特异功能，能看穿自己的想法，对他人有强烈的防范意识，与人疏离。认为自己有一些罪恶的想法，并很自责。",
            advice:
              "被测者存在精神病性症状。被测者需要到正规医院的精神科接受进一步检查，以确定症状是否确实存在，以及对应的矫治或治疗方法，如有需要需在专业医生指导下采用心理和药物的协同方式来减轻和消除这些精神症状。",
          },
          {
            name: "其他分量表",
            score: 3,
            factor_id: 221,
            mark: "存在轻度其他症状",
            comment:
              "测试结果表明，被测者可能存在轻度其他不良症状，如失眠易醒，胃口较差等。",
            advice:
              "针对被测者可能存在的问题，建议被试者从以下几个方面予以调整：一、保持乐观、知足常乐的良好心态。对社会竞争、个人得失等有充分的认识，避免因挫折致心理失衡；二、建立有规律的日常生活作息，保持人的正常睡-醒节律；三、多进行户外体育锻炼；四、养成良好的睡眠饮食卫生习惯，如保持卧室清洁、安静、远离噪音、避开光线刺激等；饮食应多注意荤素搭配，戒烟忌酒等；五、多进行自我调节、自我暗示。可做一些放松的活动，也可反复计数等，能有效的加快入睡；六、限制白天睡眠时间，除老年人白天可适当午睡或打盹片刻外，应避免午睡或打盹，否则会减少晚上的睡意及睡眠时间；七、多多亲近自然，放松紧张烦躁的心情，可以去山区近郊旅游。通过适当的户外活动，可以让自己紧张的神经得到有效的缓解，心情好，睡眠也就好，胃口自然也可以大为改善。同时，有花草树木、山清水秀的地方，空气中的离子含量也会比较高\r\n                    ，也利于对人体神经的养护。需要特别说明的是，对于部分较重的被测者，除进行上述调节外，建议尽早就医，以便在医生的指导下进行相应更多有针对性的调节和治疗。",
          },
        ],
      },
      charDataList: [],
      charDataList2: [],
      charNameList: [],
      // ----------新--------------------
      // 图表数据
      chartDataList: [],
      // 表数据
      tableInfoList: [],
      listData1: [], //数值
      listData2: [], //参考值
      listData3: [], //图示名
      form: {},
      fontSize: "16px",
      config: {},
    };
  },
  created() {
    // 获取全局配置项
    this.config = JSON.parse(localStorage.getItem("config"));
    //  获取用户id.判断单个还是多个id
    let data = {
      id: "",
    };
    if (this.$route.query.id) {
      // //console.log("单");
      this.isDegree = false;
      data.id = this.$route.query.id;
    } else if (this.$route.query.ids) {
      // //console.log("多");

      if (this.$route.query.ids.split(",").length == 1) {
        this.isDegree = false;
      }
      data.id = this.$route.query.ids;
    }
    console.log(this.$route.query);
    if (this.$route.query.isDegree) {
      this.isDegree = false;
    }
    this.degree = this.$route.query.degree;
    // ---------ajax请求---------
    loadReport(data).then((res) => {
      //console.log("数据：", res);
      this.form = res.config; //打印设置
      let tmp = 0;
      let infoTmp = [];
      if (this.$route.query.ids) {
        tmp = this.$route.query.ids.split(",").length;
      }

      if (this.$route.query.id || tmp < 2) {
        this.tableInfoList = [res.data];
        infoTmp = [res.data];
      } else {
        this.tableInfoList = res.data;
        infoTmp = res.data;
      }

      // 1-图表数据处理
      let resArr = [];

      this.tableInfoList.forEach((v, k) => {
        // --------------------------- 单独处理数据--------------------
        // 1.明尼苏达多相个性测查表-399
        if (v.initial_measure_title == "明尼苏达多相个性测查表-399") {
          console.log(v.initial_measure_title);
          return false;
        }
        if (v.initial_measure_title == "明尼苏达多相个性测查表") {
          console.log(v.initial_measure_title);
          return false;
        }
        // 阳性与阴性症状量表
        if (v.initial_measure_title == "阳性与阴性症状量表(PANSS)") {
          v.factor_results = v.factor_results.slice(18, 24);
        }
        // -----------------------------------共用报告数据处理------------------------------------------------
        let thisArr = [];
        let list1 = [];
        let list2 = [];
        let list3 = [];
        // -----.base_results 添加至 数组 .factor_results----
        if (!(v.base_results instanceof Array) && v.base_results != {}) {
          this.tableInfoList[k].factor_results.unshift(v.base_results);
        } else if (v.factor_results.length == 0) {
          this.tableInfoList[k].factor_results.unshift(v.base_results);
        }

        v.factor_results.forEach((vs, ks) => {
          //  ------------最大值和图示---------------
          let xArr = {};
          xArr["name"] = vs.name;
          // xArr["max"] = vs.reference.split("-")[1] * 1 + 3;
          xArr["max"] = vs.score * 1 + 0.5;
          thisArr[ks] = xArr;
          // -------------数据1，数据2---------------
          if (vs.score != "-") {
            list1.push(vs.score);
            // ------------------图示----------------------
            list3.push(vs.name);
          }
          list2.push(vs.reference.split("-")[1]);
        });
        resArr[k] = thisArr;
        this.listData1[k] = list1;
        this.listData2[k] = list2;
        this.listData3[k] = list3;

        // 1- 解决 程度 不存在 的数据 但是要渲染图中显示因子
        for (let i = 0; i < infoTmp[k].factor_results.length; i++) {
          this.tableInfoList[k].factor_results = this.tableInfoList[
            k
          ].factor_results.filter((v) => {
            return v.mark != "";
          });
        }
      });
      this.chartDataList = resArr;
    });
  },
  mounted() {
    // 延迟1秒，渲染图表
    this.$nextTick(() => {
      let fontS =
        JSON.parse(localStorage.getItem("config")).report_font_size + "px";
      console.log(fontS);
      this.fontSize = fontS;
      this.charData();
      setTimeout(() => {
        this.fetchData();
      }, 2000);
    });
    // 打印
  },
  methods: {
    // 最后的改变
    endChange(){
        // console.log("最后的回调");
          let id;
          if (this.$route.query.id) {
            id=this.$route.query.id
          } else if (this.$route.query.ids) {
            id=this.$route.query.ids
          }
          setPrint({id:id}).then(
            res=>{
              console.log(res);
            }
          )
    },
    // 打印
    fetchData() {
      setTimeout(() => {
        this.fullscreenLoading = false;
        // 视图更新完成后执行
        this.$nextTick(() => {
          // 点击打印
          // window.print();
          let btn = document.querySelector('#printBtn')
          btn.click();
        });
      }, 1000);
    },
    //   图表1
    charData() {
      for (let i = 0; i < this.tableInfoList.length; i++) {
        // --------基于准备好的dom，初始化echarts实例---------
        let ageData = [];
        ageData[i] = this.$echarts.init(
          document.getElementById(`chart-report${i}`)
        );
        this.myChart = ageData;
        //---------------- 处理特殊量表报告-----------------
        if (
          this.tableInfoList[i].initial_measure_title ==
            "明尼苏达多相个性测查表-399" ||
          this.tableInfoList[i].initial_measure_title ==
            "明尼苏达多相个性测查表"
        ) {
          //  图表数据处理
          let xName = []; //x轴 -下
          let x2Name = []; //x轴 -上
          let yData = [];

          for (let k1 in this.tableInfoList[i].results.mmpi) {
            // 图中数据
            if (k1 == "kCmCore") {
              for (let k2 in this.tableInfoList[i].results.mmpi[k1]) {
                if (this.tableInfoList[i].results.mmpi[k1][k2] == "") {
                  yData.push(0);
                } else {
                  yData.push(this.tableInfoList[i].results.mmpi[k1][k2]);
                }
              }
            }
            if (k1 == "oldCore") {
              for (let k2 in this.tableInfoList[i].results.mmpi[k1]) {
                xName.push(this.tableInfoList[i].results.mmpi[k1][k2] + "");
              }
            }
            if (k1 == "help") {
              for (let k2 in this.tableInfoList[i].results.mmpi[k1]) {
                x2Name.push(this.tableInfoList[i].results.mmpi[k1][k2] + "");
              }
            }
          }
          let yMax = Math.max.apply(null, yData) + 10;
          console.log(yMax);
          // echart 图表创建
          ageData[i].setOption({
            animation: false,
            backgroundColor: "white",
            title: {
              text: "基本量表剖析图(按中国常模,不加 K 校正)",
              x: "center",
              top: -5,
            },
            tooltip: {
              trigger: "axis",
            },
            grid: {
              left: "3%",
              right: "4%",
              bottom: "2%",
              top: "10%",
              containLabel: true,
            },
            xAxis: [
              {
                axisTick: {
                  show: false,
                },
                axisLabel: {
                  textStyle: {
                    fontSize: 15,
                  },
                  interval: 0,
                },
                type: "category",
                boundaryGap: ["30%", "20%"],
                name: "T-C",
                nameTextStyle: { fontSize: 14, padding: [40, 0, 10, 0] },
                nameLocation: "start",

                data: xName,
              },
              {
                axisTick: {
                  show: false,
                },
                axisLabel: {
                  textStyle: {
                    fontSize: 15,
                  },
                  interval: 0,
                },
                data: x2Name,
              },
            ],
            yAxis: [
              {
                axisLabel: {
                  textStyle: {
                    fontSize: 15,
                  },
                },
                axisLine: { show: false },
                type: "value",
                interval: 20,
                min: 0,
                max: yMax,
              },
            ],
            series: [
              {
                name: "accuracy",
                type: "line",
                itemStyle: {
                  normal: {
                    color: "#003366",
                    lineStyle: { color: "#003366" },
                    label: { show: true },
                  },
                },
                symbol: "star",
                symbolSize: 8,
                markPoint: {
                  symbolSize: 100,

                  itemStyle: {
                    normal: {
                      label: {
                        show: true,
                        fontSize: 20,
                      },
                    },
                  },
                },
                //itemStyle:{ normal: {label : {show: true}}},
                xAxisIndex: 1,
                data: yData,
              },
            ],
          });

          // 开始下次循环
          continue;
        }
        // ---------------处理通用量表报告--------------------
        //  数据处理
        let data1 = this.listData1[i].filter((val, index, arr) => {
          if (
            !(this.tableInfoList[i].base_results instanceof Array) &&
            this.tableInfoList[i].base_results != {}
          ) {
            if(this.tableInfoList[i].factor_results.length == 1) {  // 如果只有一个数据
              return index !== -1;
            }else {
              return index !== 0;
            }
          } else {
            return index !== -1;
          }
        });
        let data2 = this.listData2[i].filter((val, index, arr) => {
          if (
            !(this.tableInfoList[i].base_results instanceof Array) &&
            this.tableInfoList[i].base_results != {}
          ) {
            return index !== 0;
          } else {
            return index !== -1;
          }
        });
        let data3 = this.listData3[i].filter((val, index, arr) => {
          if (
            !(this.tableInfoList[i].base_results instanceof Array) &&
            this.tableInfoList[i].base_results != {}
          ) {
            if(this.tableInfoList[i].factor_results.length == 1) {  // 如果只有一个数据
              return index !== -1;
            }else {
              return index !== 0;
            }
          } else {
            return index !== -1;
          }
        });
        let str = this.chartDataList[i].filter((val, index, arr) => {
          if (
            !(this.tableInfoList[i].base_results instanceof Array) &&
            this.tableInfoList[i].base_results != {}
          ) {
            return index !== 0;
          } else {
            return index !== -1;
          }
        });
        // DISC性格测试 str 数据兼容处理
        if (this.tableInfoList[i].measure_title == "DISC性格测试") {
          data3 = ["支配", "影响", "稳健", "服从"];
        }
        // name 和 num 数据组合
        let arr = [];
        data1.forEach((v, k) => {
          let obj = {
            value: v,
            name: data3[k],
          };
          arr.push(obj);
        });
        let arr2 = [
          { name: this.listData3[i][0], value: this.listData1[i][0] },
        ];
        // 最大值
        let dataMax = Math.max(...data1.map(Number));
        if (
          this.tableInfoList[i].base_results.length ||
          this.tableInfoList[i].base_results.reference
        ) {
          dataMax = this.tableInfoList[i].base_results.reference * 1;
        }
        if (this.tableInfoList[i].base_results.reference == "") {
          dataMax = Math.max(...data1.map(Number));
        }
        if (this.tableInfoList[i].theme == -1) {
          break;
        }

        // 绘制图表-----------
        // 7：图标颜色方法
        var axislineColor = new this.$echarts.graphic.LinearGradient(
          0,
          0,
          1,
          0,
          [
            {
              offset: 0,
              color: "#87F3ED",
            },
            {
              offset: 0.5,
              color: "#A5B8FF",
            },

            {
              offset: 1,
              color: "#FF0000",
            },
          ]
        );
        //  2，3 多数据折线
        //  5,6  多数据圆形
        //  1、4、7 、8 单数据-仪表盘
        //  9 10 多数据柱状图

        if (this.tableInfoList[i].theme == 3) {
          console.log(4);
          ageData[i].setOption({
            backgroundColor: "transparent",
            // backgroundColor: "red",
            normal: {
              top: 200,
              left: 300,
              width: 500,
              height: 400,
              zIndex: 6,
              backgroundColor: "",
            },
            color: ["rgba(245, 166, 35, 1)", "rgba(19, 173, 255, 1)"],
            tooltip: {
              show: true,
              trigger: "item",
            },
            legend: {
              show: true,
              icon: "circle",
              left: "center",
              top: "92%",
              orient: "horizontal",
              textStyle: {
                fontSize: 14,
                color: "black",
              },
              data: ["得分"],
            },
            radar: {
              center: ["50%", "50%"],
              radius: "70%",
              startAngle: 90,
              splitNumber: 4,
              shape: "circle",
              splitArea: {
                areaStyle: {
                  color: ["transparent"],
                },
              },
              axisLabel: {
                show: false,
                fontSize: 18,
                color: "#fff",
                fontStyle: "normal",
                fontWeight: "normal",
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "grey", //
                },
              },
              splitLine: {
                show: true,
                lineStyle: {
                  color: "grey", //
                },
              },
              indicator: str,

              // [
              //   {
              //     name: "道路结冰",
              //     max: 88
              //   },
              // ]
            },
            series: [
              {
                name: "得分",
                type: "radar",
                symbol: "circle",
                symbolSize: 10,
                areaStyle: {
                  normal: {
                    color: "rgba(245, 166, 35, 0.4)",
                  },
                },
                itemStyle: {
                  color: "rgba(245, 166, 35, 1)",
                  borderColor: "rgba(245, 166, 35, 0.3)",
                  borderWidth: 10,
                },
                lineStyle: {
                  normal: {
                    type: "dashed",

                    color: "rgba(245, 166, 35, 1)",
                    width: 2,
                  },
                },
                data: [
                  // [80, 50, 55, 80, 50, 80, 48, 43, 60, 78, 60, 40, 42, 44, 65]
                  data1,
                ],
                label: {
                  normal: {
                    show: true,
                    fontSize: 14,
                    fontWeight: "bold",
                    color: "rgba(128,128,128)",
                    position: "top",
                  },
                },
              },
            ],
          });
        } else if (this.tableInfoList[i].theme == 1) {
          ageData[i].setOption({
            backgroundColor: "#fff",
            title: [
              {
                x: "center",
                bottom: 30,
                text: this.tableInfoList[i].factor_results[0].name,
                textStyle: {
                  fontWeight: "normal",
                  fontSize: 20,
                  color: "black",
                },
              },
            ],
            tooltip: {
              show: true,
            },
            series: [
              {
                type: "gauge",
                center: ["50%", "55%"], // 默认全局居中
                radius: "80%",
                splitNumber: 10, //刻度数量
                min: 0,
                max: dataMax,
                startAngle: 200,
                endAngle: -20,
                clockwise: true,
                axisLine: {
                  show: true,
                  lineStyle: {
                    width: 2,
                    shadowBlur: 0,
                    color: [[1, "#03B7C9"]],
                  },
                },
                axisTick: {
                  show: true,
                  lineStyle: {
                    color: "#03B7C9",
                    width: 1,
                  },
                  length: -15,
                  splitNumber: 10,
                },
                splitLine: {
                  show: true,
                  length: -25,
                  lineStyle: {
                    color: "#03B7C9",
                  },
                },
                axisLabel: {
                  distance: -20,
                  textStyle: {
                    color: "#03B7C9",
                    fontSize: "15",
                    fontWeight: "bold",
                  },
                },
                pointer: {
                  //仪表盘指针
                  show: 0,
                },
                detail: {
                  show: false,
                },
                data: [
                  {
                    name: "",
                    value: 100,
                  },
                ],
              },
              {
                startAngle: 200,
                endAngle: -20,
                type: "gauge",
                center: ["50%", "55%"], // 默认全局居中
                radius: "70%",
                min: 0,
                max: 1000,
                splitNumber: 0,
                axisLine: {
                  // 坐标轴线
                  lineStyle: {
                    color: [
                      [0.66, "#dddddd"],
                      [1, "#dddddd"],
                    ], // 属性lineStyle控制线条样式
                    width: 4,
                  },
                },

                axisLabel: {
                  // 坐标轴小标记
                  textStyle: {
                    // 属性lineStyle控制线条样式
                    fontWeight: "bolder",
                    fontSize: 16,
                    color: "rgba(30,144,255,0)",
                  },
                },
                splitLine: {
                  // 分隔线
                  length: 40, // 属性length控制线长
                  lineStyle: {
                    // 属性lineStyle（详见lineStyle）控制线条样式
                    width: 0,
                    color: "#444",
                  },
                },
                pointer: {
                  // 分隔线 指针
                  color: "#666666",
                  width: 0,
                  length: 400,
                },
                detail: {
                  show: false,
                },
              },
              {
                name: this.tableInfoList[i].factor_results[0].name,
                type: "gauge",
                startAngle: 200,
                endAngle: -20,
                radius: "80%",
                center: ["50%", "55%"], // 默认全局居中
                min: 0,
                max: dataMax,
                axisLine: {
                  show: false,
                  lineStyle: {
                    width: 25,
                    shadowBlur: 0,
                    color: [
                      [0.2, "#00FAFC"],
                      [0.4, "#3BD542"],
                      [0.6, "#E3F424"],
                      [0.8, "#7E48DA"],
                      [1, "red"],
                    ],
                  },
                },
                axisTick: {
                  show: false,
                },
                splitLine: {
                  show: false,
                  length: 20,
                },

                axisLabel: {
                  show: false,
                },
                pointer: {
                  show: true,
                },
                detail: {
                  show: true,
                  offsetCenter: [0, "40%"],
                  textStyle: {
                    fontSize: 30,
                  },
                },
                itemStyle: {
                  normal: {
                    color: "#03B7C9",
                  },
                },
                data: [
                  {
                    value: this.listData1[i][0],
                  },
                ],
              },
            ],
          });
        } else if (this.tableInfoList[i].theme == 2) {
          ageData[i].setOption({
            backgroundColor: "#ffffff",
            legend: {
              right: 20,
              top: 20,
            },
            tooltip: {
              trigger: "axis",
            },
            xAxis: [
              {
                type: "category",
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: "#6c50f3",
                  },
                },
                splitArea: {
                  color: "#f00",
                  lineStyle: {
                    color: "#f00",
                  },
                },
                axisLabel: {
                  lineStyle: {
                    color: "#6c50f3",
                  },
                },
                splitLine: {
                  show: false,
                },
                boundaryGap: false,
                data: data3,
              },
            ],

            yAxis: [
              {
                nameTextStyle: {
                  color: "#6c50f3",
                  fontSize: 14,
                },
                type: "value",
                max: dataMax,
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: "rgba(255,255,255,0.1)",
                  },
                },
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: "#6c50f3",
                  },
                },
                axisLabel: {
                  show: true,
                  margin: 20,
                  textStyle: {
                    color: "#6c50f3",
                  },
                },
                axisTick: {
                  show: true,
                  lineStyle: {
                    color: "#6c50f3",
                  },
                },
              },
            ],
            series: [
              {
                type: "line",
                smooth: true,
                showAllSymbol: true,
                symbol: "circle",
                symbolSize: 15,
                lineStyle: {},
                label: {
                  show: true,
                  position: "top",
                  textStyle: {
                    color: "#6c50f3",
                  },
                },
                itemStyle: {
                  color: "#6c50f3",
                  borderColor: "#fff",
                  borderWidth: 3,
                  shadowColor: "rgba(0, 0, 0, .3)",
                  shadowBlur: 0,
                  shadowOffsetY: 2,
                  shadowOffsetX: 2,
                },
                tooltip: {
                  show: false,
                },
                areaStyle: {
                  normal: {
                    color: new this.$echarts.graphic.LinearGradient(
                      0,
                      0,
                      0,
                      1,
                      [
                        {
                          offset: 0,
                          color: "rgba(108,80,243,0.3)",
                        },
                        {
                          offset: 1,
                          color: "rgba(108,80,243,0)",
                        },
                      ],
                      false
                    ),
                    shadowColor: "rgba(108,80,243, 0.9)",
                    shadowBlur: 20,
                  },
                },
                data: data1,
              },
            ],
          });
        } else if (this.tableInfoList[i].theme == 4) {
          let value = this.listData1[i][0];
          ageData[i].setOption({
            animation: false,
            backgroundColor: "#fff",
            title: {
              z: 999,
              text: `${value}分`,
              // subtext: this.tableInfoList[i].factor_results[0].name,
              left: "center",
              top: "center", //top待调整
              textStyle: {
                color: "#fff",
                fontSize: 60,
                fontFamily: "DINAlternate-Bold",
              },
              subtextStyle: {
                color: "#ff",
                fontSize: 35,
                fontFamily: "PingFangSC-Regular",
                top: "center",
              },
              itemGap: -4, //主副标题间距
            },
            xAxis: {
              splitLine: {
                show: false,
              },
              axisLabel: {
                show: false,
              },
              axisLine: {
                show: false,
              },
              // data: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
            },
            yAxis: {
              splitLine: {
                show: false,
              },
              axisLabel: {
                show: false,
              },
              axisLine: {
                show: false,
              },
            },
            series: [
              // 进度圈
              {
                type: "pie",
                clockWise: true,
                radius: ["60%", "55%"],
                data: [
                  {
                    value: value,
                    itemStyle: {
                      normal: {
                        borderWidth: 10,
                        borderColor: {
                          colorStops: [
                            {
                              offset: 0,
                              color: "transparent" || "transparent", // 0% 处的颜色
                            },
                            {
                              offset: 1,
                              color: "transparent" || "transparent", // 100% 处的颜色
                            },
                          ],
                        },
                        color: {
                          // 完成的圆环的颜色
                          colorStops: [
                            {
                              offset: 0,
                              color: "transparent" || "transparent", // 0% 处的颜色
                            },
                            {
                              offset: 1,
                              color: "transparent" || "transparent", // 100% 处的颜色
                            },
                          ],
                        },
                        label: {
                          show: false,
                        },
                        labelLine: {
                          show: false,
                        },
                      },
                    },
                  },
                  {
                    name: "gap",
                    value: 100 - value,
                    itemStyle: {
                      normal: {
                        label: {
                          show: false,
                        },
                        labelLine: {
                          show: false,
                        },
                        color: "rgba(0, 0, 0, 0)",
                        borderColor: "rgba(0, 0, 0, 0)",
                        borderWidth: 0,
                      },
                    },
                  },
                ],
              },
              //刻度尺
              {
                // name: "白色圈刻度",
                type: "gauge",
                radius: "75%",
                startAngle: 225, //刻度起始
                endAngle: -134.8, //刻度结束
                z: 4,
                axisTick: {
                  show: true,
                  lineStyle: {
                    width: 2,
                    color: "rgba(1,244,255, 0.9)",
                  },
                },
                splitLine: {
                  length: 16, //刻度节点线长度
                  lineStyle: {
                    width: 2,
                    color: "rgba(1,244,255, 0.9)",
                  }, //刻度节点线
                },
                axisLabel: {
                  color: "rgba(255,255,255,0)",
                  fontSize: 12,
                }, //刻度节点文字颜色
                pointer: {
                  show: false,
                },
                axisLine: {
                  lineStyle: {
                    opacity: 0,
                  },
                },
                detail: {
                  show: false,
                },
                data: [
                  {
                    value: 0,
                    name: "",
                  },
                ],
              },
              //最外层圈
              {
                type: "pie",
                radius: ["0%", "95%"],
                center: ["50%", "50%"],
                avoidLabelOverlap: false,
                hoverAnimation: false,
                label: {
                  normal: {
                    show: false,
                    position: "center",
                  },
                  emphasis: {
                    show: false,
                    textStyle: {
                      fontWeight: "bold",
                    },
                  },
                },
                itemStyle: {
                  normal: {
                    color: {
                      type: "linear",
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      // 外
                      colorStops: [
                        {
                          offset: 0,
                          color: "rgba(63,245,175)",
                        },

                        {
                          offset: 1,
                          color: "rgb(128,250,120)",
                        },
                      ],
                    },
                  },
                },
                labelLine: {
                  normal: {
                    show: false,
                  },
                },
                data: [
                  {
                    value: 3235,
                  },
                ],
              },
              // 刻度圈
              {
                type: "pie",
                radius: ["0%", "85%"],
                center: ["50%", "50%"],
                avoidLabelOverlap: false,
                label: {
                  normal: {
                    show: false,
                    position: "center",
                  },
                  emphasis: {
                    show: false,
                    textStyle: {
                      fontWeight: "bold",
                    },
                  },
                },
                itemStyle: {
                  normal: {
                    color: {
                      type: "linear",
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      // 中
                      colorStops: [
                        {
                          offset: 0,
                          color: "rgb(187,249,255)",
                        },

                        {
                          offset: 1,
                          color: "rgb(243,249,251)",
                        },
                      ],
                    },
                  },
                },
                labelLine: {
                  normal: {
                    show: false,
                  },
                },
                data: [
                  {
                    value: 3235,
                  },
                ],
              },
              // 内圆
              {
                type: "pie",
                radius: ["0", "50%"],
                center: ["50%", "50%"],
                z: 99,
                itemStyle: {
                  normal: {
                    color: new this.$echarts.graphic.LinearGradient(
                      0,
                      0,
                      0,
                      1,
                      // 内
                      [
                        {
                          offset: 0,
                          color: "rgb(97,225,250)",
                        },

                        {
                          offset: 1,
                          color: "rgb(61,183,255)",
                        },
                      ]
                    ),
                    label: {
                      show: false,
                    },
                    labelLine: {
                      show: false,
                    },
                  },
                },
                label: {
                  normal: {
                    position: "center",
                  },
                },
                data: [100],
              },
            ],
          });
        } else if (this.tableInfoList[i].theme == 5) {
          ageData[i].setOption({
            backgroundColor: "#fff",
            color: [
              "#EAEA26",
              "#906BF9",
              "#FE5656",
              "#01E17E",
              "#3DD1F9",
              "#FFAD05",
            ],
            // title: {
            //     text: '网络/安全设备',
            //     left: '60',
            //     top: 0,
            //     textAlign: 'center',
            //     textStyle: {
            //         color: '#fff',
            //         fontSize: 14,
            //         fontWeight: 0
            //     }
            // },
            grid: {
              left: -100,
              top: 50,
              bottom: 10,
              right: 10,
              containLabel: true,
            },
            tooltip: {
              trigger: "item",
              formatter: "{b} : {c} ({d}%)",
            },
            legend: {
              type: "scroll",
              orient: "vartical",
              // x: "right",
              top: "center",
              right: "15",
              // bottom: "0%",
              itemWidth: 16,
              itemHeight: 8,
              itemGap: 16,
              textStyle: {
                color: "#A3E2F4",
                fontSize: 12,
                fontWeight: 0,
              },
              data: data3,
            },
            polar: {},
            angleAxis: {
              interval: 1,
              type: "category",
              data: [],
              z: 10,
              axisLine: {
                show: false,
                lineStyle: {
                  color: "#0B4A6B",
                  width: 1,
                  type: "solid",
                },
              },
              axisLabel: {
                interval: 0,
                show: true,
                color: "#0B4A6B",
                margin: 8,
                fontSize: 16,
              },
            },
            radiusAxis: {
              min: 40,
              max: 120,
              interval: 20,
              axisLine: {
                show: false,
                lineStyle: {
                  color: "#0B3E5E",
                  width: 1,
                  type: "solid",
                },
              },
              axisLabel: {
                formatter: "{value} %",
                show: false,
                padding: [0, 0, 20, 0],
                color: "#0B3E5E",
                fontSize: 16,
              },
              splitLine: {
                lineStyle: {
                  color: "#0B3E5E",
                  width: 2,
                  type: "solid",
                },
              },
            },
            calculable: true,
            series: [
              {
                type: "pie",
                radius: ["5%", "10%"],
                hoverAnimation: false,
                labelLine: {
                  normal: {
                    show: false,
                    length: 30,
                    length2: 55,
                  },
                  emphasis: {
                    show: false,
                  },
                },
                data: [
                  {
                    name: "",
                    value: 0,
                    itemStyle: {
                      normal: {
                        color: "#0B4A6B",
                      },
                    },
                  },
                ],
              },
              {
                type: "pie",
                radius: ["90%", "95%"],
                hoverAnimation: false,
                labelLine: {
                  normal: {
                    show: false,
                    length: 30,
                    length2: 55,
                  },
                  emphasis: {
                    show: false,
                  },
                },
                name: "",
                data: [
                  {
                    name: "",
                    value: 0,
                    itemStyle: {
                      normal: {
                        color: "#0B4A6B",
                      },
                    },
                  },
                ],
              },
              {
                stack: "a",
                type: "pie",
                radius: ["20%", "80%"],
                roseType: "area",
                zlevel: 10,
                label: {
                  normal: {
                    show: true,
                    formatter: "{c}",
                    textStyle: {
                      fontSize: 12,
                    },
                    position: "outside",
                  },
                  emphasis: {
                    show: true,
                  },
                },
                labelLine: {
                  normal: {
                    show: true,
                    length: 20,
                    length2: 55,
                  },
                  emphasis: {
                    show: false,
                  },
                },
                data: arr,
              },
            ],
          });
        } else if (this.tableInfoList[i].theme == 6) {
          ageData[i].setOption({
            backgroundColor: "#fff",
            tooltip: {
              trigger: "item",
              formatter: "{b} : {c} ({d}%)",
            },

            visualMap: {
              show: false,
              min: 500,
              max: 600,
              inRange: {
                //colorLightness: [0, 1]
              },
            },
            series: [
              {
                name: "访问来源",
                type: "pie",
                radius: "60%",
                center: ["50%", "50%"],
                color: [
                  "rgb(255,159,64)",
                  "rgb(178,34,34)",
                  "rgb(255,205,86)",
                  "rgb(75,192,192)",
                  "rgb(54,162,235)",
                  "rgb(84,120,228)",
                  "rgb(121,106,210)",
                  "rgb(32,66,101)",
                  "rgb(140,255,25)",
                  "rgb(255,97,3)",
                ], //'#FBFE27','rgb(11,228,96)','#FE5050'
                data: arr.sort(function (a, b) {
                  return a.value - b.value;
                }),
                roseType: "radius",

                label: {
                  normal: {
                    formatter: ["{c|{c}分}", "{b|{b}}"].join("\n"),
                    rich: {
                      c: {
                        color: "black",
                        fontSize: 14,
                        fontWeight: "bold",
                        lineHeight: 5,
                      },
                      b: {
                        color: "rgb(98,137,169)",
                        fontSize: 14,
                        height: 30,
                      },
                    },
                  },
                },
                labelLine: {
                  normal: {
                    lineStyle: {
                      color: "rgb(98,137,169)",
                    },
                    smooth: 0.2,
                    length: 20,
                    length2: 10,
                  },
                },
                itemStyle: {
                  normal: {
                    shadowColor: "rgba(0, 0, 0, 1)",
                  },
                },
              },
            ],
          });
        } else if (this.tableInfoList[i].theme == 7) {
          ageData[i].setOption({
            series: [
              {
                name: "车辆总数",
                type: "gauge",
                z: 3,
                min: 0,
                max: dataMax,
                splitNumber: 10,
                radius: "80%",
                axisLine: {
                  // 坐标轴线
                  lineStyle: {
                    // 属性lineStyle控制线条样式
                    width: 21,
                    color: [[1, axislineColor]],
                  },
                },
                axisTick: {
                  show: false,
                },
                axisLabel: {
                  show: true,
                  distance: -55,
                  textStyle: {
                    color: "#333333",
                    fontSize: 12,
                    fontWeight: 500,
                  },
                },
                splitLine: {
                  // 分隔线
                  show: false,
                },
                title: {
                  offsetCenter: [0, 18],
                  textStyle: {
                    // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                    color: "white",
                    fontSize: 20,
                  },
                },
                detail: {
                  offsetCenter: [0, 100],
                  textStyle: {
                    // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                    color: "#007FFF",
                    fontSize: 40,
                    fontWeight: 500,
                  },
                  formatter: function (value) {
                    return `${value}分`;
                  },
                },
                itemStyle: {
                  normal: {
                    color: "#8492AA",
                  },
                },
                data: arr2,
              },
              {
                name: "",
                type: "gauge",
                z: 2,
                min: 0,
                max: 100,
                splitNumber: 10,
                radius: "70%",
                axisLine: {
                  // 坐标轴线
                  show: false,
                  lineStyle: {
                    // 属性lineStyle控制线条样式
                    width: 0,
                    color: [[1, axislineColor]],
                  },
                },
                title: {
                  show: false,
                },
                detail: {
                  show: false,
                },
                axisTick: {
                  show: true,
                  length: 15,
                  lineStyle: {
                    width: 3,
                    color: "#979797",
                  },
                },
                axisLabel: {
                  show: false,
                },
                splitLine: {
                  // 分隔线
                  show: false,
                },
              },
            ],
          });
        } else if (this.tableInfoList[i].theme == 8) {
          console.log("arr", arr2);

          ageData[i].setOption({
            backgroundColor: "#fff",
            tooltip: {
              position: "inside",
              formatter:
                "小于20%有发展空间<br>20%~40%为健康<br>40%~60%为预警<br>大于60%为过度",
              textStyle: {
                fontSize: 10,
              },
            },
            series: [
              {
                name: "刻度",
                type: "gauge",
                center: ["50%", "70%"],
                radius: "90%",
                min: 0, //最小刻度
                max: 100, //最大刻度
                splitNumber: 8, //刻度数量
                startAngle: 180,
                endAngle: 0,
                axisLine: {
                  show: true,
                  lineStyle: {
                    width: 1,
                    color: [[1, "rgba(0,0,0,0)"]],
                  },
                }, //仪表盘轴线
                axisLabel: {
                  show: true,
                  color: "#050505",
                  fontSize: 15,
                  distance: -50,
                  formatter: function (v) {
                    return v;
                  },
                }, //刻度标签。
                axisTick: {
                  show: true,
                  splitNumber: 5,
                  lineStyle: {
                    color: "#050505",
                    width: 1,
                  },
                  length: -3,
                }, //刻度样式
                splitLine: {
                  show: true,
                  length: -5,
                  lineStyle: {
                    color: "#050505",
                  },
                }, //分隔线样式
              },
              {
                type: "gauge",
                radius: "80%",
                center: ["50%", "70%"],
                splitNumber: 0, //刻度数量
                startAngle: 180,
                endAngle: 0,
                axisLine: {
                  show: true,
                  lineStyle: {
                    width: 50,
                    color: [
                      [0.52, "#00a65a"],
                      [0.63, "#EEC900"],
                      [0.72, "#ff6600"],
                      [1, "#ff0000"],

                      // [0.4, "#2b64fc"],
                      // [0.6, "#f39c11"],
                      // [1, "#fa4e4b"]
                    ],
                  },
                },
                //分隔线样式。
                splitLine: {
                  show: false,
                },
                axisLabel: {
                  show: false,
                },
                axisTick: {
                  show: false,
                },
                pointer: {
                  show: true,
                  length: "60%",
                  width: "7%",
                },
                title: {
                  show: false,
                  offsetCenter: [0, "60%"], // x, y，单位px
                  textStyle: {
                    fontWeight: "bold",
                    color: "#050505",
                    fontSize: 30,
                  },
                },
                //仪表盘详情，用于显示数据。
                detail: {
                  show: true,
                  offsetCenter: [0, "30%"],
                  color: "#ffffff",

                  formatter: function (value) {
                    if (value <= 20) {
                      return "有发展空间";
                    } else if (value <= 40) {
                      return "健康";
                    } else if (value <= 60) {
                      return "预警";
                    } else {
                      return "过度";
                    }
                  },
                  textStyle: {
                    fontSize: 30,
                  },
                },
                data: arr2,
              },
              {
                // 仅显示tooltip用
                type: "pie",
                radius: "90%",
                hoverAnimation: true,
                label: {
                  normal: {
                    show: false,
                    position: "center",
                  },
                },
                labelLine: {
                  normal: {
                    show: false,
                  },
                },
                z: 3,
                zlevel: 0,
                itemStyle: {
                  borderWidth: 0,
                },
                data: [
                  {
                    value: 50,
                    name: "",
                    label: {
                      normal: {
                        show: false,
                      },
                    },
                    itemStyle: {
                      normal: {
                        color: "transparent",
                      },
                    },
                  },
                ],
              },
            ],
          });
        } else if (this.tableInfoList[i].theme == 88) {
          console.log(this.tableInfoList[i]);
          ageData[i].setOption({
            backgroundColor: "#fff",
            tooltip: {
              position: "inside",
              formatter:
                "小于20%有发展空间<br>20%~40%为健康<br>40%~60%为预警<br>大于60%为过度",
              textStyle: {
                fontSize: 10,
              },
            },
            series: [
              {
                name: "刻度",
                type: "gauge",
                center: ["50%", "70%"],
                radius: "90%",
                min: 0, //最小刻度
                max: 100, //最大刻度
                splitNumber: 8, //刻度数量
                startAngle: 180,
                endAngle: 0,
                axisLine: {
                  show: true,
                  lineStyle: {
                    width: 1,
                    color: [[1, "rgba(0,0,0,0)"]],
                  },
                }, //仪表盘轴线
                axisLabel: {
                  show: true,
                  color: "#050505",
                  fontSize: 20,
                  distance: -50,
                  formatter: function (v) {
                    return v;
                  },
                }, //刻度标签。
                axisTick: {
                  show: true,
                  splitNumber: 5,
                  lineStyle: {
                    color: "#050505",
                    width: 1,
                  },
                  length: -3,
                }, //刻度样式
                splitLine: {
                  show: true,
                  length: -5,
                  lineStyle: {
                    color: "#050505",
                  },
                }, //分隔线样式
              },
              {
                type: "gauge",
                radius: "80%",
                center: ["50%", "70%"],
                splitNumber: 0, //刻度数量
                startAngle: 180,
                endAngle: 0,
                axisLine: {
                  show: true,
                  lineStyle: {
                    width: 50,
                    color: [
                      [0.49, "#00a65a"],
                      [0.59, "#EEC900"],
                      [0.69, "#ff6600"],
                      [1, "#ff0000"],

                      // [0.4, "#2b64fc"],
                      // [0.6, "#f39c11"],
                      // [1, "#fa4e4b"]
                    ],
                  },
                },
                //分隔线样式。
                splitLine: {
                  show: false,
                },
                axisLabel: {
                  show: false,
                },
                axisTick: {
                  show: false,
                },
                pointer: {
                  show: true,
                  length: "60%",
                  width: "7%",
                },
                title: {
                  show: false,
                  offsetCenter: [0, "60%"], // x, y，单位px
                  textStyle: {
                    fontWeight: "bold",
                    color: "#050505",
                    fontSize: 30,
                  },
                },
                //仪表盘详情，用于显示数据。
                detail: {
                  show: true,
                  offsetCenter: [0, "30%"],
                  color: "#ffffff",

                  formatter: function (value) {
                    if (value <= 20) {
                      return "有发展空间";
                    } else if (value <= 40) {
                      return "健康";
                    } else if (value <= 60) {
                      return "预警";
                    } else {
                      return "过度";
                    }
                  },
                  textStyle: {
                    fontSize: 30,
                  },
                },
                data: arr2,
              },
              {
                // 仅显示tooltip用
                type: "pie",
                radius: "90%",
                hoverAnimation: true,
                label: {
                  normal: {
                    show: false,
                    position: "center",
                  },
                },
                labelLine: {
                  normal: {
                    show: false,
                  },
                },
                z: 3,
                zlevel: 0,
                itemStyle: {
                  borderWidth: 0,
                },
                data: [
                  {
                    value: 50,
                    name: "",
                    label: {
                      normal: {
                        show: false,
                      },
                    },
                    itemStyle: {
                      normal: {
                        color: "transparent",
                      },
                    },
                  },
                ],
              },
            ],
          });
        } else if (this.tableInfoList[i].theme == 9) {
          var max9 = "";
          data3.forEach((v) => {
            if (v.length > max9.length) {
              max9 = v;
            }
          });
          var xp = max9.length * 3;
          ageData[i].setOption({
            backgroundColor: "#fff",
            grid: {
              top: "10%",
              right: "10%",
              left: "10%",
              bottom: xp + "%",
            },
            xAxis: [
              {
                type: "category",
                color: "#59588D",
                data: data3,
                axisLabel: {
                  rotate: 30,
                  margin: 20,
                  color: "#999",
                  textStyle: {
                    fontSize: 14,
                  },
                },
                axisLine: {
                  lineStyle: {
                    color: "rgba(107,107,107,0.37)",
                  },
                },
                axisTick: {
                  show: false,
                },
              },
            ],
            yAxis: [
              {
                min: 0,
                max: dataMax,
                axisLabel: {
                  formatter: "{value}",
                  color: "#999",
                  textStyle: {
                    fontSize: 18,
                  },
                },
                axisLine: {
                  lineStyle: {
                    color: "rgba(107,107,107,0.37)",
                  },
                },
                axisTick: {
                  show: false,
                },
                splitLine: {
                  lineStyle: {
                    color: "rgba(131,101,101,0.2)",
                    type: "dashed",
                  },
                },
              },
            ],
            series: [
              {
                type: "bar",
                data: data1,
                barWidth: "30px",
                itemStyle: {
                  normal: {
                    color: new this.$echarts.graphic.LinearGradient(
                      0,
                      0,
                      0,
                      1,
                      [
                        {
                          offset: 0,
                          color: "#FF9A22", // 0% 处的颜色
                        },
                        {
                          offset: 1,
                          color: "#FFD56E", // 100% 处的颜色
                        },
                      ],
                      false
                    ),
                    barBorderRadius: [30, 30, 0, 0],
                  },
                },
                label: {
                  normal: {
                    show: true,
                    fontSize: 18,
                    fontWeight: "bold",
                    color: "#333",
                    position: "top",
                  },
                },
              },
              {
                data: data1,
                type: "line",
                smooth: true,
                name: "折线图",
                symbol: "none",
                lineStyle: {
                  color: "#3275FB",
                  width: 4,
                  shadowColor: "rgba(0, 0, 0, 0.3)", //设置折线阴影
                  shadowBlur: 15,
                  shadowOffsetY: 20,
                },
              },
            ],
          });
        } else if (this.tableInfoList[i].theme == 10) {
          ageData[i].setOption({
            backgroundColor: "#fff",
            grid: {
              left: "12%",
              top: "10%",
              bottom: "20%",
              right: "8%",
            },
            xAxis: {
              data: data3,
              axisTick: {
                show: false,
              },
              axisLine: {
                lineStyle: {
                  color: "rgba(255, 129, 109, 0.1)",
                  width: 1, //这里是为了突出显示加上的
                },
              },
              axisLabel: {
                rotate: 30,
                textStyle: {
                  color: "black",
                  fontSize: 14,
                },
              },
            },
            yAxis: [
              {
                min: 0,
                max: dataMax,
              },
              {
                splitNumber: 2,
                axisTick: {
                  show: false,
                },
                axisLine: {
                  lineStyle: {
                    color: "black",
                    width: 1, //这里是为了突出显示加上的
                  },
                },
                axisLabel: {
                  textStyle: {
                    color: "black",
                  },
                },
                splitArea: {
                  areaStyle: {
                    color: "rgba(255,255,255,.5)",
                  },
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: "black",
                    width: 0.5,
                    type: "dashed",
                  },
                },
              },
            ],
            series: [
              {
                name: "hill",
                type: "pictorialBar",
                barCategoryGap: "0%",
                symbol:
                  "path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z",
                label: {
                  show: true,
                  position: "top",
                  distance: 15,
                  color: "black",
                  fontWeight: "bolder",
                  fontSize: 16,
                },
                itemStyle: {
                  normal: {
                    color: {
                      type: "linear",
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      colorStops: [
                        {
                          offset: 0,
                          color: "rgba(232, 94, 106, .8)", //  0%  处的颜色
                        },
                        {
                          offset: 1,
                          color: "rgba(232, 94, 106, .1)", //  100%  处的颜色
                        },
                      ],
                      global: false, //  缺省为  false
                    },
                  },
                  emphasis: {
                    opacity: 1,
                  },
                },
                data: data1,
                z: 10,
              },
            ],
          });
        } else if (this.tableInfoList[i].theme == 11) {
          ageData[i].setOption({
            backgroundColor: "#fff",
            color: [
              "rgb(255,159,64)",
              "rgba(178,34,34,0.7)",
              "rgba(255,205,86,0.7)",
              "rgba(75,192,192,0.7)",
              "rgba(54,162,235,0.7)",
              "rgba(84,120,228,0.7)",
              "rgba(121,106,210,0.7)",
              "rgba(32,66,101,0.7)",
              "rgba(140,255,25,0.7)",
              "rgba(255,97,3,0.7)",
            ],
            grid: {
              left: -100,
              top: 10,
              bottom: 10,
              right: 10,
              containLabel: true,
            },
            tooltip: {
              trigger: "item",
              formatter: "{b} : {c} ({d}%)",
            },
            legend: {
              type: "scroll",
              orient: "vartical",
              // x: "right",
              top: "center",
              right: "15",
              // bottom: "0%",
              itemWidth: 16,
              itemHeight: 8,
              itemGap: 16,
              textStyle: {
                color: "black",
                fontSize: 12,
                fontWeight: 0,
              },
              data: data3,
            },
            polar: {},
            angleAxis: {
              interval: 1,
              type: "category",
              data: [],
              z: 10,
              axisLine: {
                show: false,
                lineStyle: {
                  color: "#0B4A6B",
                  width: 1,
                  type: "solid",
                },
              },
              axisLabel: {
                interval: 0,
                show: true,
                color: "#0B4A6B",
                margin: 8,
                fontSize: 16,
              },
            },
            radiusAxis: {
              min: 0,
              max: Math.ceil(dataMax),
              interval: Math.ceil(dataMax / 5),
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#black",
                  width: 1,
                  type: "solid",
                },
              },
              axisLabel: {
                formatter: "{value} 分",
                show: true,
                padding: [0, 0, 10, 0],
                color: "black",
                fontSize: 10,
              },
              splitLine: {
                lineStyle: {
                  color: "#black",
                  width: 1,
                  type: "solid",
                },
              },
            },
            calculable: true,
            series: [
              {
                type: "pie",
                radius: ["50%", "50%"],
                hoverAnimation: false,
                labelLine: {
                  normal: {
                    show: false,
                    length: 30,
                    length2: 55,
                  },
                  emphasis: {
                    show: false,
                  },
                },
                name: "",
                data: [
                  {
                    name: "",
                    value: 0,
                    itemStyle: {
                      normal: {
                        color: "#0B4A6B",
                      },
                    },
                  },
                ],
              },
              {
                stack: "a",
                type: "pie",
                radius: ["0%", "80%"],
                roseType: "area",
                zlevel: 1,
                itemStyle: {
                  //图形样式
                  normal: {
                    borderColor: "rgba(255,255,255,0.5)",
                    borderWidth: 6,
                  },
                },
                label: {
                  normal: {
                    show: true,
                    formatter: "{b}-{c}",
                    textStyle: {
                      fontSize: 14,
                      color: "black",
                    },
                    position: "outside",
                  },
                  emphasis: {
                    show: true,
                  },
                },
                labelLine: {
                  normal: {
                    lineStyle: {
                      color: "black",
                    },
                    length: 20,
                    length2: 10,
                  },
                },
                data: arr,
              },
            ],
          });
        } else {
          // 获取最大值
          ageData[i].setOption({
            backgroundColor: "#FFFFFF",
            xAxis: {
              type: "category",
              data: data3,
              axisLabel: {
                interval: 0,
                rotate: 15,
                show: true,
                splitNumber: 15,
                textStyle: {
                  fontFamily: "微软雅黑",
                  fontSize: 10,
                },
              },
            },
            yAxis: {
              type: "value",
              // max: 300
              max: Math.ceil(dataMax * 1.5),
            },
            grid: {
              bottom: 100,
            },
            dataZoom: {
              height: 15,
              type: "slider", //图表下方的伸缩条
              show: false, //是否显示
              realtime: true,
              start: 1,
              end: 100,
            },
            series: [
              {
                data: data1,
                type: "bar",
                name: "柱状图",
                barWidth: 15,
                min: 0,
                // max: 200,
                itemStyle: {
                  normal: {
                    //每个柱子的颜色即为colorList数组里的每一项，如果柱子数目多于colorList的长度，则柱子颜色循环使用该数组
                    color: function (params) {
                      var colorList = [
                        "rgb(102,186,248)",
                        "rgb(35,150,231)",
                        "rgb(119,115,189)",
                      ];
                      return colorList[params.dataIndex % colorList.length];
                    },
                  },
                },
              },
              {
                data: data1,
                type: "line",
                name: "折线图",
                symbolSize: 10, // 控制线条上 点 的大小
                itemStyle: {
                  normal: {
                    color: "#70CC58", //折点颜色
                    label: {
                      show: true,
                      position: "top",
                      color: "#333",
                      fontSize: 14,
                      fontWeight: 700,
                    },
                    borderWidth: 5,
                    lineStyle: {
                      color: "#70CC58", //折线颜色
                      width: 5,
                    },
                  },
                },
              },
            ],
          });
        }
      }
    },
  },
  filters: {
    // 预警
    waiting(val) {
      return val != 1 ? "正常" : "危险";
    },
    filtrationBirthday(val) {
      let cuTime = moment().format("YYYY");
      let newAge = cuTime - val.split("-")[0];
      return newAge;
    },
  },
  watch: {
    chartDataList: function () {
      this.$nextTick(() => {
        this.charData();
      });
    },
  },
};
</script>

<style lang="less">
@media print {
  .printbtn {
    display: none;
  }
  .PageNext {
    page-break-after: always;
  }
  .seal {
    page-break-inside: avoid;
  }
  // .page{
  //   margin: 100px 0;
  // }
}
.printbtn {
  position: absolute;
  top: 50px;
  left: 20px;
}
.main-article {
  padding: 20px;
  display: block;
  background: #fff;
}
// 报告内容部分
.content {
  box-sizing: border-box;
  width: 900px;
  margin: auto;
  background: white;
  padding: 10px 60px 10px 60px;
  // 报表头部
  .report-title {
    font-size: 30px;
    text-align: center;
  }
  //   用户信息
  .userInfo {
    margin-top: 20px;
    table {
      margin: auto;
      width: 100%;
      border-top: 1px black dashed;
      border-left: 1px black dashed;
      tr {
        width: 100%;
        // font-size: 16px;
        font-weight: 700;
        td {
          padding: 5px;
          border-bottom: 1px black dashed;
          border-right: 1px black dashed;
          &:nth-child(1) {
            width: 220px;
          }
          &:nth-child(2) {
            width: 180px;
          }
          &:nth-child(3) {
            min-width: 300px;
          }
          span {
            // font-size: 14px;
            font-weight: 300;
          }
        }
      }
    }
  }
  //   测试结果标题.
  .test-msg {
    padding-left: 0px;
    margin-top: 10px;
    font-size: 25px;
    color: orange;
    text-shadow: black 2px 1px 1px;
    font-weight: 700;
  }
  //    图表
  .chart {
    margin-top: 10px;
    height: 350px;
    #chart-report {
      margin: 0 auto;
      //   border: black 1px solid;
    }
  }
  //   表格
  .table {
    overflow: hidden;
    margin-top: 15px;
    box-sizing: border-box;
    padding: 20px 0;
    border-top: 1px black solid;
    border-bottom: 1px black solid;
    .table-main {
      .one,
      .two {
        width: 100%;
        border-top: 1px black dashed;
        border-left: 1px black dashed;
        tr {
          width: 100%;
          th {
            border-right: 1px black dashed;
            border-bottom: 1px black dashed;
            padding: 5px 0;
          }
          td {
            text-align: center;
            border-right: 1px black dashed;
            border-bottom: 1px black dashed;
            padding: 5px 0;
            font-size: 14px;
            &:nth-child(1) {
              // width: 180px;
            }
            &:nth-child(2) {
              // width: 150px;
            }
            &:nth-child(3) {
              min-width: 40px;
              text-align: center;
            }
            .table-icon {
              padding-left: 5px;
            }
          }
        }
      }
      .one {
      }
      .results-two {
        width: 100%;
        border-top: 1px dashed black;
        border-left: 1px dashed black;
        tr {
          th {
            padding: 5px 0;
            border-bottom: 1px dashed black;
            border-right: 1px dashed black;
            &:nth-child(1) {
              width: 160px;
            }
            &:nth-child(2) {
              width: 180px;
            }
            &:nth-child(3) {
              width: 50px;
            }

            &:nth-child(4) {
              width: 160px;
            }
            &:nth-child(5) {
              width: 180px;
            }
            &:nth-child(6) {
              width: 50px;
            }
          }
          td {
            box-sizing: border-box;
            padding: 2px 5px 2px 5px;
            vertical-align: middle;
            border-bottom: 1px dashed black;
            border-right: 1px dashed black;
            text-align: center;
            &:nth-child(1) {
              width: 160px;
            }
            &:nth-child(2) {
              width: 180px;
            }
            &:nth-child(3) {
              width: 50px;
            }

            &:nth-child(4) {
              width: 160px;
            }
            &:nth-child(5) {
              width: 180px;
            }
            &:nth-child(6) {
              width: 50px;
            }
          }
        }
      }
    }
  }
  //   健康状态
  .condition {
    margin-top: 10px;
    border-bottom: 1px black solid;
    padding-bottom: 14px;
    table {
      tr {
        td {
          text-indent: 2em;
          letter-spacing: 3px;
          line-height: 25px;
          // font-size: 15px;
        }
      }
      .condition-title {
        text-indent: 0;
        padding-top: 10px;
        // font-size: 16px;
        font-weight: 700;
      }
    }
  }
  //   每项分析
  .analyze {
    margin-top: 20px;
    padding-bottom: 10px;
    // letter-spacing: 4px;

    table {
      padding: 10px 0;
      border-bottom: 1px black solid;
      width: 100%;
      tr {
        td {
          text-indent: 2em;
          // letter-spacing: 3px;
            letter-spacing: 6px !important;
          line-height: 25px;
          // font-size: 15px;
        }
      }
      .analyze-title {
        text-indent: 0;
        padding-top: 10px;
        // font-size: 16px;
        font-weight: 700;
      }
    }
  }
  // 签名
  .signature {
    position: relative;
    text-align: right;
    margin-top: 40px;
    .title {
      font-weight: 700;
      .signature_img {
        display: inline-block;
        min-width: 100px;
      }
    }
    .data {
      margin-left: 20px;
    }
    img {
      // position: absolute;
      // top: -40px;
      // left: 284px;
    }
  }
}
// 明尼苏达
.minnesota {
  transform: translateX(-55px);
  .title {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
  }
  .remind {
    border-top: 1px black solid;
    border-bottom: 1px black solid;
    padding: 5px 0;
  }
  .el-divider--horizontal {
    margin: 5px 0;
  }
  .el-divider {
    background-color: rgb(133, 133, 133);
  }
  .mmpi {
    margin-top: 40px;
    .mmpi_table {
      margin-top: 20px;
      table:nth-child(1) {
        width: 100%;
        font-size: 14px;
        tr:nth-child(1) {
          display: flex;
          td {
            flex: 1;
            display: flex;
            justify-content: center;
            &:nth-child(1) {
              flex: 4;
              text-align: left;
              justify-content: flex-start;
            }
            span {
              display: inline-block;
              // text-align: center;
              height: 120px;
              writing-mode: vertical-lr;
              letter-spacing: 8px;
            }
          }
        }
        tr:nth-child(2) {
          display: flex;
          td {
            flex: 1;
            display: flex;
            justify-content: center;
            &:nth-child(1) {
              flex: 4;
              text-align: left;
              justify-content: flex-start;
            }
            span {
              display: inline-block;
              text-align: center;
              padding: 5px 0;
            }
          }
        }
      }
      table:nth-child(3) {
        width: 100%;
        font-size: 14px;
        tr {
          display: flex;
          td {
            flex: 1;
            display: flex;
            justify-content: center;
            &:nth-child(1) {
              flex: 4;
              text-align: left;
              justify-content: flex-start;
            }
            span {
              display: inline-block;
              text-align: center;
              padding: 5px 0;
            }
          }
        }
      }
      .mmpi_summarize {
        padding: 10px 0;
      }
    }
  }
  .t {
    margin-top: 40px;
    .t_table {
      margin-top: 20px;
      > table:nth-child(1) {
        width: 100%;
        font-size: 14px;
        tr {
          display: flex;
          > td {
            flex: 1;
            display: flex;
            justify-content: center;
            > span {
              display: inline-block;
              text-align: center;
              padding: 5px 0;
            }
          }
        }
      }
      > table:nth-child(2) {
        width: 100%;
        font-size: 14px;
        border: black 1px solid;
        border-collapse: collapse;
        tr {
          &:nth-child(1) {
            td:nth-child(4) {
              // display: flex;
              span {
                display: inline-block;
                width: 9%;
                justify-content: space-around;
                position: relative;
                text-align: center;
                &::after {
                  font-size: 17px;
                  content: "|";
                  position: absolute;
                  bottom: -16px;
                  right: 9px;
                  z-index: 999;
                }
                &::before {
                  font-size: 10px;
                  content: "|||||||||";
                  position: absolute;
                  bottom: -11px;
                  right: -3px;
                  z-index: 999;
                }
              }
            }
          }
          td {
            text-align: center;
            border-right: black 1px solid;
            border-bottom: black 1px solid;
            &:nth-child(1) {
              width: 100px;
            }
            &:nth-child(2) {
              width: 50px;
            }
            &:nth-child(3) {
              width: 180px;
            }
            &:nth-child(4) {
              position: relative;
              text-align: left;
              font-size: 10px;
            }
            &:nth-child(5) {
              width: 150px;
            }
          }
        }
        .cursor {
          color: black;
          display: inline-block;
          position: absolute;
          .el-icon-caret-top {
            font-size: 20px;
          }
        }
      }
      .forecast {
        margin-top: 30px;
        table {
          width: 100%;
          border-collapse: separate;
          border-spacing: 5px 5px;
          caption {
            font-size: 16px;
            font-weight: 700;
            padding: 10px 0;
          }
          tr {
            &:nth-child(2) {
              font-weight: 700;
              td {
                &:nth-child(1) {
                  width: 120px;
                }
              }
            }
            td {
              width: 50px;
              text-align: right;
            }
          }
        }
      }
    }
  }
  .conclusion {
    margin-top: 40px;
    table {
      width: 100%;
      border-collapse: collapse;
      caption {
        padding: 5px 0;
        font-weight: 700;
        font-size: 18px;
      }
      tr {
        th {
          border-top: 1px black solid;
          border-bottom: 1px black solid;
          padding: 5px 0;
          &:nth-child(1) {
            width: 200px;
            text-align: center;
          }
          &:nth-child(2) {
            width: 150px;
            text-align: center;
          }
          &:nth-child(3) {
          }
        }
        td {
          font-size: 14px;
          padding: 5px 0;
          &:nth-child(1) {
          }
          &:nth-child(2) {
            text-align: center;
          }
        }
      }
    }
  }
  .auxiliary {
    margin-top: 40px;
    .auxiliary_title {
      margin-top: 20px;
      font-weight: 700;
      font-size: 16px;
      text-align: center;
    }
    .auxiliary_main {
      .auxiliary_item {
        padding: 10px 0;
        .item_title {
          font-weight: 700;
          margin-bottom: 8px;
        }
      }
    }
  }
  .describe {
    margin-top: 40px;
    .describe_main {
      .describe_content_title {
        font-size: 16px;
        text-align: center;
        p {
          margin: 30px 0px 9px 0px;
          font-size: 16px;
          font-weight: 700;
        }
      }
      .describe_content {
        font-size: 14px;
        padding: 10px 0;
      }
    }
  }
  .zDescribe {
    margin-top: 60px;
    .zDescribe_title {
      font-weight: 700;
      text-align: center;
    }
    .zDescribe_content {
      margin-top: 40px;
      font-size: 14px;
    }
    .zDescribe_msg {
      margin-top: 50px;
      p {
        font-weight: 700;
        span {
          font-weight: 100;
        }
      }
    }
  }
  .suggest {
    border-top: 1px black solid;
    margin-bottom: 5px;
    padding: 5px 0;
    .suggest_msg {
      font-weight: 700;
    }
    .suggest_content {
      height: 200px;
    }
  }
}
</style>
